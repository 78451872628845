





















import Vue from "vue";
import { Component } from "vue-property-decorator";

import { api as templateApi } from "@/store/modules/template";
import { FilterUiApi } from "@auditcloud/shared/lib/utils/filter/types";
import AItemFilterAction from "@auditcloud/components/widgets/sidebar/AItemFilterAction.vue";

@Component({
  components: {
    AItemFilterAction,
  },
})
export default class ATemplateItemFilterAction extends Vue {
  readonly filterUIApi: FilterUiApi = {
    namespace: templateApi.namespace,
    getters: {
      getAggregations:
        templateApi.getters.getTemplateItemListFilterAggregations,
      getFulltextSearch: templateApi.getters.getFullTextSearch,
    },
    mutations: {
      TOGGLE_FILTER: templateApi.mutations.TOGGLE_FILTER_SETTING,
      SET_FILTERS: templateApi.mutations.SET_FILTERS,
      CLEAR_FILTER_FOR_AGGREGATIONS:
        templateApi.mutations.CLEAR_FILTER_FOR_AGGREGATIONS,
      CLEAR_ALL_FILTERS: templateApi.mutations.CLEAR_FILTER_SETTINGS,
      SET_FULLTEXT_SEARCH: templateApi.mutations.SET_FULLTEXT_SEARCH,
    },
  };
}
